import {
  IconCalendarMediumOutline,
  IconContactsMediumOutline,
  IconCheckmarkBadgedMediumOutline,
  IconProjectsMediumOutline,
  IconInvoiceMediumOutline,
  IconWorkorderMediumOutline,
  IconHeadsetMediumFilled,
  IconTimerMediumOutline,
  IconStatsMediumOutline,
  IconProductsMediumOutline,
  IconKanbanMediumOutline,
} from '@teamleader/ahoy';
import { ElementType } from 'react';

export enum Feature {
  Calendar = 'calendar',
  CustomerManagement = 'customer_management',
  Quotations = 'quotations',
  ProductsAndServices = 'products_and_services',
  Statistics = 'statistics',
  InvoicesAndPayments = 'invoices_and_payments',
  ProjectManagement = 'project_management',
  TimeTracking = 'time_tracking',
  WorkOrders = 'work_orders',
  Tickets = 'tickets',
  Planning = 'planning',
}

export const getIncludedFeatures = (): { id: string; icon: ElementType }[] => [
  {
    id: Feature.Calendar,
    icon: IconCalendarMediumOutline,
  },
  {
    id: Feature.CustomerManagement,
    icon: IconContactsMediumOutline,
  },
  {
    id: Feature.Quotations,
    icon: IconCheckmarkBadgedMediumOutline,
  },
  {
    id: Feature.ProductsAndServices,
    icon: IconProductsMediumOutline,
  },
  {
    id: Feature.TimeTracking,
    icon: IconTimerMediumOutline,
  },
  {
    id: Feature.Statistics,
    icon: IconStatsMediumOutline,
  },
];

export const getOptionalFeatures = (): { id: string; icon: ElementType }[] => [
  {
    id: Feature.InvoicesAndPayments,
    icon: IconInvoiceMediumOutline,
  },
  {
    id: Feature.ProjectManagement,
    icon: IconProjectsMediumOutline,
  },
  {
    id: Feature.WorkOrders,
    icon: IconWorkorderMediumOutline,
  },
  {
    id: Feature.Tickets,
    icon: IconHeadsetMediumFilled,
  },
  {
    id: Feature.Planning,
    icon: IconKanbanMediumOutline,
  },
];
