import jwtDecode from 'jwt-decode';
import { path } from 'ramda';
import { takeLatest, takeEvery, call, put, take, select, fork } from 'redux-saga/effects';
import { v7 } from 'uuid';

import * as Analytics from '../../../infrastructure/analytics/actions';
import { SignupApi, ReferralApi } from '../../../infrastructure/api';
import { getCookieConsentPayload } from '../../../infrastructure/cookieConsent/utils';
import { selectLocationType, selectQueryParams } from '../../App/selectors';
import { getIncludedFeatures, getOptionalFeatures } from '../FeaturesSurveyStep/constants';
import { CompanyForm } from '../ProfileStep/CompanyFormSelector/companyForm';
import { CompanySize } from '../ProfileStep/CompanySizeSelector/companySize';
import {
  VALIDATE_EMAIL,
  emailValidated,
  SUBMIT_STEP,
  INIT_SIGNUP,
  TRACK,
  track,
  setReferralToken,
  PARSE_IDENTITY_PROVIDER_TOKEN,
  setIdentityProviderToken,
  setIdentityProviderError,
  submitStep,
  PREVIOUS_STEP,
  previousStep,
} from '../actions';
import { emailErrors, STEP_EMAIL, STEP_FEATURES, STEP_PROFILE, STEP_SUBMITTING } from '../constants';
import { IdentityProviderErrorType } from '../identityProvider';
import { ROUTE_SIGNUP_MOBILE } from '../routes';
import {
  selectStepIndex,
  selectFormValues,
  selectEmail,
  selectIsSandboxed,
  selectReferralToken,
  selectStepsOrder,
  selectSignupFeatures,
  selectIdentityProviderToken,
  selectHasCampaign,
  selectCampaign,
  selectHasPartner,
  selectPartner,
  selectShouldSkipFeaturesStep,
  selectIsExternal,
} from '../selectors';
import { AnswerState } from '../types/answerState';
import { QuestionType } from '../types/questionType';

import { getRecaptchaToken, waitForRecaptchaReady } from './recaptcha';
import { signup as signupWithUser } from './signup';

const RECAPTCHA_HEADER = 'G-Recaptcha-Response';

export const getEmailValidation = function* (email, recaptchaToken) {
  const { data } = yield call(
    SignupApi.emailAddresses.validate,
    {
      email,
    },
    recaptchaToken
      ? {
          fetchOptions: {
            headers: {
              'G-Recaptcha-Response': recaptchaToken,
            },
          },
        }
      : undefined,
  );
  return data;
};

export const validateEmail = function* ({ payload: { email, recaptchaToken } }) {
  let emailAvailable, emailValid, emailError;

  try {
    const { available, valid } = yield call(getEmailValidation, email, recaptchaToken);

    emailAvailable = available;
    emailValid = valid;
  } catch (error) {
    emailError = emailErrors.FORMAT;
  }

  if (!emailError) {
    if (!emailAvailable) {
      emailError = emailErrors.UNAVAILABLE;
    } else if (!emailValid) {
      emailError = emailErrors.INVALID;
    }
  }

  yield put(emailValidated({ email, error: emailError }));
};

export const handleParseIdentityProviderToken = function* ({ payload }) {
  yield put(setIdentityProviderError(undefined));

  let token;

  try {
    token = yield call(jwtDecode, payload.token);
  } catch {
    yield put(
      setIdentityProviderError({ message: IdentityProviderErrorType.InvalidToken, provider: payload.provider }),
    );
  }

  if (token) {
    const { available: isEmailUnique } = yield call(getEmailValidation, token.email, payload.recaptchaToken);

    if (isEmailUnique) {
      yield put(setIdentityProviderToken({ ...token, token: payload.token }));
    } else {
      yield put(
        setIdentityProviderError({
          message: IdentityProviderErrorType.AlreadyInUse,
          provider: payload.provider ?? token.provider_type,
        }),
      );
    }
  }
};

export const getSubmissionId = (() => {
  let submissionId;

  return () => {
    if (!submissionId) {
      submissionId = v7();
    }

    return submissionId;
  };
})();

export const sanitiseFirstName = function* (firstName) {
  const firstNameTrimmed = firstName.trim();

  if (!firstNameTrimmed.match(/\s/)) {
    return firstNameTrimmed;
  }

  yield put(
    track({
      event: 'signupWizard.firstNameSpacesRemoved',
      source: 'signupWizard',
    }),
  );

  return firstNameTrimmed.replace(/\s+/g, '-');
};

export const getEmailStepValues = function* () {
  const fieldValues = yield select(selectFormValues);

  return {
    email: fieldValues.email.trim(),
    termsOfService: {
      text: fieldValues.termsOfService.text,
      accepted: true,
    },
    marketingEmailsConsent: fieldValues.marketingEmailsConsent,
  };
};

export const getSignupStartValues = function* () {
  const cookieConsent = yield call(getCookieConsentPayload);
  const emailStepValues = yield call(getEmailStepValues);
  const regionStepValues = yield call(getRegionStepValues);
  const locationType = yield select(selectLocationType);
  const hasPartner = yield select(selectHasPartner);

  const payload = {
    ...emailStepValues,
    cookieConsent,
    language: regionStepValues.language,
  };

  if (locationType === ROUTE_SIGNUP_MOBILE) {
    payload.flow = 'mobile';
  }

  if (hasPartner === true) {
    const partner = yield select(selectPartner);
    payload.partner = partner;
  }

  return payload;
};

export const getProfileStepValues = function* () {
  const fieldValues = yield select(selectFormValues);

  const values = {
    firstName: yield call(sanitiseFirstName, fieldValues.firstName),
    lastName: fieldValues.lastName.trim(),
    companyName: fieldValues.companyName.trim(),
    telephone: {
      number: fieldValues.telephone.trim(),
      country: fieldValues.telephoneCountry,
    },
  };

  return values;
};

export const getRegionStepValues = function* () {
  const fieldValues = yield select(selectFormValues);

  return {
    country: fieldValues.country,
    language: fieldValues.language,
    timeZone: fieldValues.timeZone,
    currency: fieldValues.currency,
  };
};

export const getSurveyStepValues = function* () {
  const signupFeatures = yield select(selectSignupFeatures);
  const fieldValues = yield select(selectFormValues);

  const survey = [
    {
      questionType: QuestionType.SignupFeatures,
      answers: [
        ...getIncludedFeatures().map((feature) => ({
          option: feature.id,
          state: AnswerState.Default,
        })),
        ...getOptionalFeatures().map((feature) => ({
          option: feature.id,
          state: signupFeatures.includes(feature.id) ? AnswerState.Checked : AnswerState.UnChecked,
        })),
      ],
    },
    {
      questionType: QuestionType.CompanyForm,
      answers: [
        {
          option: fieldValues.companyForm,
          state: AnswerState.Checked,
        },
        ...Object.values(CompanyForm)
          .filter((companyForm) => companyForm !== fieldValues.companyForm)
          .map((companyForm) => ({ option: companyForm, state: AnswerState.UnChecked })),
      ],
    },
    {
      questionType: QuestionType.CompanySize,
      answers: [
        {
          option: fieldValues.companySize,
          state: AnswerState.Checked,
        },
        ...Object.values(CompanySize)
          .filter((companySize) => companySize !== fieldValues.companySize)
          .map((companySize) => ({ option: companySize, state: AnswerState.UnChecked })),
      ],
    },
  ];

  return { survey: survey };
};

export const signUp = function* (action) {
  const submissionId = yield call(getSubmissionId);
  const { password } = yield select(selectFormValues);
  const isSandboxed = yield select(selectIsSandboxed);
  const hasCampaign = yield select(selectHasCampaign);
  const hasPartner = yield select(selectHasPartner);
  const referralToken = yield select(selectReferralToken);
  const identityProviderToken = yield select(selectIdentityProviderToken);
  const cookieConsent = yield call(getCookieConsentPayload);

  const payload = {
    id: submissionId,
    password,
    cookieConsent,
    ...(yield call(getEmailStepValues)),
    ...(yield call(getProfileStepValues)),
    ...(yield call(getRegionStepValues)),
    ...(yield call(getSurveyStepValues)),
  };

  if (isSandboxed === true) {
    payload.sandbox = true;
  }

  if (hasCampaign === true) {
    const campaign = yield select(selectCampaign);
    payload.source = campaign;
  }

  if (hasPartner === true) {
    const partner = yield select(selectPartner);
    payload.partner = partner;
  }

  if (referralToken !== undefined) {
    payload.referralToken = referralToken;
  }

  if (identityProviderToken?.token) {
    payload.identityProviderSignupToken = identityProviderToken.token;
  }

  yield call(signupWithUser, payload);
};

export const fetchReferralToken = function* () {
  let referralToken;
  try {
    referralToken = (yield call(ReferralApi.referrals.identify)).data.token;
  } catch (error) {
    return;
  }

  if (referralToken) {
    yield put(setReferralToken(referralToken));
  }
};

export const initSignup = function* () {
  const params = yield select(selectQueryParams);

  if (params?.signupFeatures && !Array.isArray(params.signupFeatures)) {
    params.signupFeatures = [params.signupFeatures];
  }

  yield put(
    track({
      event: 'signupWizard.started',
      source: 'signupWizard',
      meta: {
        ...params,
      },
    }),
  );

  yield call(fetchReferralToken);
};

export const intermediateSubmit = function* (endpoint, getSubmissionData) {
  yield call(waitForRecaptchaReady);

  const payload = yield call(getSubmissionData);
  const email = yield select(selectEmail);
  const submissionId = yield call(getSubmissionId);

  const requestPayload = {
    id: submissionId,
    email,
    ...payload,
  };

  if (yield select(selectIsSandboxed)) {
    requestPayload.sandbox = true;
  }

  if (yield select(selectHasCampaign)) {
    const campaign = yield select(selectCampaign);
    requestPayload.source = campaign;
  }

  try {
    const recaptchaToken = yield call(getRecaptchaToken, `signup/${endpoint.split('.')[1]}`);

    yield call(path(endpoint.split('.'), SignupApi), requestPayload, {
      fetchOptions: {
        credentials: 'include',
        ...(recaptchaToken && {
          headers: {
            [RECAPTCHA_HEADER]: recaptchaToken,
          },
        }),
      },
    });
  } catch (error) {}
};

export const handlePreviousStep = function* (action) {
  const stepsOrder = yield select(selectStepsOrder);
  const currentStep = yield select(selectStepIndex);
  const shouldSkipFeaturesStep = yield select(selectShouldSkipFeaturesStep);

  if (currentStep === stepsOrder.indexOf(STEP_FEATURES) && shouldSkipFeaturesStep) {
    yield put(previousStep());
  }
};

export const handleSubmitStep = function* (action) {
  const stepsOrder = yield select(selectStepsOrder);
  const currentStep = yield select(selectStepIndex);
  const shouldSkipFeaturesStep = yield select(selectShouldSkipFeaturesStep);
  const submittedStep = action.payload.stepIndex;

  if (currentStep === stepsOrder.indexOf(STEP_FEATURES) && shouldSkipFeaturesStep) {
    yield put(submitStep({ stepIndex: currentStep }));
  }

  if (currentStep === stepsOrder.indexOf(STEP_SUBMITTING)) {
    yield call(signUp, action);
    return;
  }

  if (submittedStep === stepsOrder.indexOf(STEP_EMAIL)) {
    yield put(setIdentityProviderError(undefined));
    yield call(intermediateSubmit, 'signup.start', getSignupStartValues);
  } else if (submittedStep === stepsOrder.indexOf(STEP_PROFILE)) {
    yield call(intermediateSubmit, 'signup.personalize', getProfileStepValues);
  }
};

export const trackSignupAction = function* ({ payload: { event, source, meta: originalMeta = {} } }) {
  const accountSignupId = yield call(getSubmissionId);
  const isSandboxed = yield select(selectIsSandboxed);
  const hasCampaign = yield select(selectHasCampaign);
  const hasPartner = yield select(selectHasPartner);
  const referralToken = yield select(selectReferralToken);
  const identityProviderToken = yield select(selectIdentityProviderToken);
  const isExternal = yield select(selectIsExternal);

  const meta = {
    identityProvider: undefined,
    ...originalMeta,
    accountSignupId,
  };

  const identityProviderType = identityProviderToken?.provider_type;
  if (identityProviderType) {
    meta.identityProvider = identityProviderType;
  }

  if (isSandboxed) {
    meta.sandbox = true;
  }

  if (hasCampaign) {
    const campaign = yield select(selectCampaign);
    meta.source = campaign;
  }

  if (hasPartner) {
    const { type } = yield select(selectPartner);
    meta.partnerType = type;
  }

  if (referralToken !== undefined) {
    meta.referral = true;
  }

  if (isExternal) {
    meta.external = true;
  }

  yield put(
    Analytics.track({
      event,
      source,
      meta,
    }),
  );
};

export default function* () {
  yield takeEvery(TRACK, trackSignupAction);
  yield take(INIT_SIGNUP);
  yield fork(initSignup);

  yield takeLatest(PARSE_IDENTITY_PROVIDER_TOKEN, handleParseIdentityProviderToken);
  yield takeLatest(VALIDATE_EMAIL, validateEmail);
  yield takeLatest(PREVIOUS_STEP, handlePreviousStep);
  yield takeLatest(SUBMIT_STEP, handleSubmitStep);
}
