export const DEFAULT_LANGUAGE = 'en';

const availableLanguages = [
  'fr',
  'de',
  'de-CH',
  'fr-BE',
  'it',
  'en',
  'tlh-KL',
  'nl-BE',
  'de-DE',
  'fr-FR',
  'es',
  'nl',
  'it-IT',
  'es-ES',
  'nl-NL',
];

if (ENABLE_KLINGON) {
  availableLanguages.unshift('tlh-KL');
}

export const SUPPORTED_LANGUAGES = availableLanguages;
